.bgWrap {
    position: fixed;
    height: 80vh;
    width: 100vw;
    overflow: hidden;
    z-index: -1;
    /*backgroundPosition: "center",*/
    /*backgroundSize: "cover",*/
    /*height: "80vh",*/
    /*color: "#f5f5f5",*/
}

.miniProfileZoom {
    width: 100%;
    height: 100%;
}

.imgRoundedCorner {
    overflow: hidden;
    border-radius: 5pt;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.miniProfileOptions {
    transition: transform 0.3s ease-out;
    /*max-width: 100%;*/
    /*border-radius: 5pt;*/
}

.miniProfileZoom img {
    transition: transform 0.3s ease-out;
    max-width: 100%;
    border-radius: 5pt;
}

.miniProfileZoom:hover img{
    transform: scale(1.05);
}

.showAddToWishList {
    display: block;
    border-radius: 3pt;
    background-color: #04040499;
    padding-left: 5pt;
    padding-right: 5pt;
    padding-bottom: 3pt;
    margin-left: 3pt;
    margin-right: 3pt;
}

.hideAddToWishList {
    display: none;
}
